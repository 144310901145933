import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

// Initialize Firebase
const config = {
	apiKey: "AIzaSyC5vv36vjpc7-4bz5W4EVsyoTyO_mVYYFY",
	authDomain: "all-in-one-crm-b8cdf.firebaseapp.com",
	databaseURL: "https://global-insurance-llc.firebaseio.com",
	projectId: "all-in-one-crm-b8cdf",
	storageBucket: "all-in-one-crm-b8cdf.appspot.com",
	messagingSenderId: "636371279703",
	measurementId: "G-J95BDDN8BF",
	appId: "1:636371279703:web:50e1079a2ffa94e7516812",
};

firebase.initializeApp(config);
const auth = firebase.auth();
const firestore = firebase.firestore();
const storage = firebase.storage();
const functions = firebase.functions();
const fieldValue = firebase.firestore.FieldValue;

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

// Ignore undefined properties
firebase.firestore().settings({
	ignoreUndefinedProperties: true,
});

export { auth, firestore, storage, functions, fieldValue, googleAuthProvider, githubAuthProvider, facebookAuthProvider, twitterAuthProvider };
